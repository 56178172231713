import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function AboutUs() {
  return (
    <>
    <section className='aboutus'>
        <Container>
            <Row>
                <Col>
                    <h2>About Us</h2>
                    <Breadcrumb className='breadcrum-1'>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>
    </section>
    <section className='aboutus-middle'>
            <Container>
                <Row>
                    <Col sm={6}>
                    <img src='../images/Dhansheera-store.jpg' />
                    </Col>
                    
                    <Col sm={6} className="whoWeAre">
                    <h3>Who Are We?</h3>
                    <p>
           An e-commerce platform DHANSHEERA is an m-commerce marketplace for your daily shopping. It allows you to shop from your favorite store in your neighborhood and get delivery within 45 Minutes to 2 hours. You can shop for groceries, Fruits & Vegetables, Bakery items, Flowers, Meats, Pet Care, Baby Care, and Cosmetics products with just a few taps. You can get everything delivered instantly or schedule it for a convenient time later. We work on a marketplace model to replace the trip to local shops for our consumers by bringing merchants (and their inventory) online. You can shop for groceries, Bakery, Pet Supplies, Cosmetics, and soon pharmacies and appliances on the DHANSHEERA
            </p>
            <p>
            Our aim is to provide facilities and services to make them digitalization. Along with we are providing services to Cuttack and Bhubaneswar areas and growing bigger to be Uttar pardesh’s first comprehensive e-commerce company.
            </p>

            <p>
            Buy grocery online in Cuttack Bhubaneswar at DHANSHEERA and get the best price on categories shopping on groceries & organic staples, branded foods, household essentials, baby care products, beauty & personal health care, food & beverages, dairy & bakery, fresh exotic fruits & vegetables, fresh chicken, meat, fish and classic eggs, Patanjali Ayurved through website/app. If you are a job holder and you have no time to buy groceries in the daily market and you are looking for online grocery shopping in Cuttack or Best Online grocery shopping in Bhubaneswar then DHANSHEERA is your best choice.
            </p>

            <p>
            DHANSHEERA is the best online grocery shopping eCommerce service provider in Uttar pardesh where the buyer can get delivery within 2 hours at your doorstep.
            </p>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
    
  )
}

export default AboutUs;
