import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Footer() {
  return (
    <>
      <section style={{marginTop:"45px", backgroundColor:"#f9f9f9", padding:"32px 0"}}>
        <Container>
            <Row>
            <Col className='quickline'>
                <h6 style={{fontWeight:"bold"}}>QUICK LINKS</h6>
                <ul>
                <li style={{marginTop:"16px"}}>
                <Nav>
                  <Nav.Link href="/" active><img src='./images/arrow-1.png'/>Home</Nav.Link>
                </Nav>
                </li>
                <li style={{marginTop:"4px"}}>
                <Nav>
                  <Nav.Link href="/about" active><img src='./images/arrow-1.png'/>About Us</Nav.Link>
                </Nav>
                </li>
                <li style={{marginTop:"4px"}}>
                <Nav>
                  <Nav.Link href="/contact" active><img src='./images/arrow-1.png'/>Contact Us</Nav.Link>
                </Nav>
                </li>
                </ul>
            </Col>
            <Col className='quickline'>
                <h6 style={{fontWeight:"bold"}}>USEFUL LINKS</h6>
                <ul>
                <li style={{marginTop:"16px"}}><img src='./images/arrow-1.png'/>Privacy Policy</li>
                <li style={{marginTop:"20px"}}><img src='./images/arrow-1.png'/>Terms & Conditions</li>
                <li style={{marginTop:"20px"}}><img src='./images/arrow-1.png'/>FAQs</li>
                </ul>
            </Col>
            <Col className='quickline'>
            <h6 style={{fontWeight:"bold"}}>CERTIFICATION</h6>
            <img style={{width:"40%"}} src='./images/fssai.png'/>
            <p>Food Safety and Standards Authority of India License under FSS Act, 2006 is <span>12023999000057</span></p>
            </Col>
            <Col className='quickline'>
            <h6 style={{fontWeight:"bold"}}>REG. OFFICE ADDRESS</h6>
            <p>
            <span>DHANSHEERA</span><br />
            DOSTPUR ROAD GOHANNA, NEAR BYPAS AKBARPUR AMBEDKARNAGAR, Uttar pardesh-224122 IN
            </p>
            </Col>
            <Col className='quickline'>
            <h6 style={{fontWeight:"bold"}}>DOWNLOAD DHANSHEERA APP:</h6>
            <a href='https://play.google.com/store/apps/details?id=com.dhansheera' target='_blank'>
            <img style={{marginTop:"16px", width:"60%"}} src='./images/googlePlay.png'/>

            </a>
            </Col>
            </Row>
        </Container>
        </section>
    </>
  )
}

export default Footer;
