import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderNavBar from './Component/HeaderNavBar';
import MainSection from './Component/MainSection';
import Footer from './Component/Footer';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AboutUs from './Component/AboutUs';
import Contact from './Component/Contact';



function App() {
  return (
    <section>
      <BrowserRouter>
      <HeaderNavBar />
        <Routes>
          <Route path='/' element={<MainSection/>}></Route>
          <Route path='/about' element={<AboutUs/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
        </Routes>

      </BrowserRouter>
      <Footer></Footer>
    </section>
  );
}

export default App;
