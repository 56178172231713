import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Contact() {
  return (
    <>
        <section className='aboutus'>
            <Container>
                <Row>
                    <Col>
                        <h2>Contact Us</h2>
                        <Breadcrumb className='breadcrum-1'>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
        </section>
      <section className='ContactUs'>
        <Container>
            <Row>
            <Col md={{ span: 10, offset: 1 }}>
                <Row className='contact-wrap '>
                    <Col>
                    <div class="contact-info">
                            <h3>Get in touch</h3>
                            <p>Feel Free to connect for any suggestion ,feedback and query.</p>
                            <ul className='contact-info'>
                                <li><i class="ti-location-pin"></i> DOSTPUR ROAD GOHANNA, NEAR BYPAS AKBARPUR AMBEDKARNAGAR- 224122</li>
                                <li><i class="ti-mobile"></i> +91 9956479300, +91 9956169300 </li>
                                <li><i class="ti-email"></i> dhansheera.app@gmail.com</li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                    <h3> DROP US A LINE</h3>
                            <p>Feedback and Question?</p>
                   

                            <Form>
                                <Row>
                                    <Col>
                                    <Form.Control placeholder="Name" />
                                    </Col>
                                    <Col>
                                    <Form.Control type="email" placeholder="Email" />
                                    </Col>
                                </Row>
                                <Row style={{marginTop: "16px"}}>
                                    <Col>
                                    <Form.Control as="textarea" rows={3} placeholder="Message" />
                                    </Col>
                                </Row>
                                <Button style={{marginTop: "16px"}} variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                    </Col>
                </Row>
            </Col>
            </Row>
        </Container>
      </section>
    </>
  )
}

export default Contact;
