import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CarousalImages from '../Component/CarousalImages';


const MainSection = (props) => {
  return (
    <>
    <CarousalImages></CarousalImages>
    <section id='KIPs'>
      <Container>
        <Row>
          <Col className='column-info'>
            <Row>
              <Col sm={4} className='image-icon1'><img src='./images/free_shipping.png'/></Col>
              <Col sm={8}>
              <span className='title1'>Free Shipping</span><br />
              <span>Free Shipping Pan India</span>
              </Col>
            </Row>
            
          </Col>
          <Col className='column-info'>
            <Row>
              <Col sm={4} className='image-icon2'><img src='./images/24_7_service.png'/></Col>
              <Col sm={8}>
              <span className='title1'>24 x 7 Service</span><br />
              <span>Online Service For 24 x 7</span>
              </Col>
            </Row>
            
          </Col><Col className='column-info'>
            <Row>
              <Col sm={4} className='image-icon3'><img src='./images/online_pay.png'/></Col>
              <Col sm={8}>
              <span className='title1'>Online<br/>Pay</span><br />
              <span>Online Payment Available</span>
              </Col>
            </Row>
            
          </Col><Col className='column-info'>
            <Row>
              <Col sm={4} className='image-icon4'><img src='./images/festival_offer.png'/></Col>
              <Col sm={8}>
              <span className='title1'>Festival Offer</span><br />
              <span>Super Sale Upto 50% off</span>
              </Col>
            </Row>
            
          </Col><Col className='column-info'>
            <Row>
              <Col sm={4} className='image-icon5'><img src='./images/organic.png'/></Col>
              <Col sm={8}>
              <span className='title1'>100%<br /> Original</span><br />
              <span>100% Money Back</span>
              </Col>
            </Row>
            
          </Col>
        </Row>
      </Container>
    </section>

    <section>
      <div>
      <Col className='imgBanner1'>
      <img src='./images/image_banner_1.jpg'/>
      </Col>
      </div>
    </section>

    <section className='image_app'>
      <Container>
        <Row>
          <Col md={6} className='mobile_app_image'><img src='./images/mobile_image.png'/></Col>
          <Col md={6} className='app_details'>
            <h1>Get the Dhansheera app</h1>
            <p>We will send you a link, open it on your phone to download the app</p>
            <Form className='emailApp'>
            <div className="mb-3">
                <Form.Check
                  inline
                  label="Email"
                  name="group1"
                  type="radio"
                />
                <Form.Check
                  inline
                  label="Phone"
                  name="group1"
                  type="radio"
                />
                
              </div>

              <Row className="align-items-center">
              <Col>
                <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                  Name
                </Form.Label>
                <Form.Control
                  className="mb-2"
                  id="inlineFormInput"
                  placeholder="Email"
                />
              </Col>

              <Col xs="auto">
                <Button type="submit" className="mb-2">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>

          <p className='googlePlayStore'>Downalod app from</p>
          <a className='googlePlayStoreImg' href='https://play.google.com/store/apps/details?id=com.dhansheera' target='_blank'><img src='./images/googlePlay.png'/></a>
          </Col>
        </Row>
        
      </Container>
    </section>

    <section>
      <div>
      <Col className='imgBanner1'>
      <img src='./images/image_banner_2.jpg'/>
      </Col>
      </div>
    </section>
    <section>
      <div>
      <Col className='imgBanner2'>
      <img src='./images/image_banner_3.jpg'/>
      </Col>
      </div>
    </section>

    <section className='extraSection'>
      <Container>
        <Row>
          <Col className='extraSectionBox'>
            <p><img src='./images/cashOnDelivery.png'/></p>
            <h4>Cash on Delivery Available</h4>
            <p>We will deliver your product seamlessly to your doorstep.</p>
          </Col>
          <Col className='extraSectionBox'>
            <p><img src='./images/payments.png'/></p>
            <h4>Multimode Payment options</h4>
            <p>More options than your need, have your own choice.</p>
          </Col>
          <Col>
            <p><img src='./images/Shakehand.png'/></p>
            <h4>Have Genuine Products</h4>
            <p>We guarantee for the genuine products you will receive.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section className='banner_Section_4'>
      <Container>
        <Row>
          <Col><img src='./images/image_banner_4.png'/></Col>
          <Col><img src='./images/image_banner_5.png'/></Col>
        </Row>
        <Row>
          <Col><img src='./images/image_banner_6.jpg'/></Col>
          <Col><img src='./images/image_banner_7.jpg'/></Col>
        </Row>
      </Container>
    </section>

    <section className='Aboutus'>
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <h4>
            About DHANSHEERA
(Online Grocery Shopping and Delivery Services in Bhubaneswar, Cuttack, Pipili Puri, Trisulia, Jagatpur, Salepur, Niali Uttar pardesh)
            </h4>
            <p>
           An e-commerce platform DHANSHEERA is an m-commerce marketplace for your daily shopping. It allows you to shop from your favorite store in your neighborhood and get delivery within 45 Minutes to 2 hours. You can shop for groceries, Fruits & Vegetables, Bakery items, Flowers, Meats, Pet Care, Baby Care, and Cosmetics products with just a few taps. You can get everything delivered instantly or schedule it for a convenient time later. We work on a marketplace model to replace the trip to local shops for our consumers by bringing merchants (and their inventory) online. You can shop for groceries, Bakery, Pet Supplies, Cosmetics, and soon pharmacies and appliances on the DHANSHEERA
            </p>
            <p>
            Our aim is to provide facilities and services to make them digitalization. Along with we are providing services to Cuttack and Bhubaneswar areas and growing bigger to be Uttar pardesh’s first comprehensive e-commerce company.
            </p>

            <p>
            Buy grocery online in Cuttack Bhubaneswar at DHANSHEERA and get the best price on categories shopping on groceries & organic staples, branded foods, household essentials, baby care products, beauty & personal health care, food & beverages, dairy & bakery, fresh exotic fruits & vegetables, fresh chicken, meat, fish and classic eggs, Patanjali Ayurved through website/app. If you are a job holder and you have no time to buy groceries in the daily market and you are looking for online grocery shopping in Cuttack or Best Online grocery shopping in Bhubaneswar then DHANSHEERA is your best choice.
            </p>

            <p>
            DHANSHEERA is the best online grocery shopping eCommerce service provider in Uttar pardesh where the buyer can get delivery within 2 hours at your doorstep.
            </p>
          </Col>
        </Row>
        
      </Container>
    </section>
    <section className='HowOrder'>
      <Container>
      <Row>
        <Col>
            <div className='HowOffer'>
              <h4>How do I Order?</h4>
              <ul>
                <li>
                <img src='./images/circle_list.png'/>
                <p>
Browse DHANSHEERA for products or use the search feature. We would recommend using a search feature that searches everything as you type.</p>
                </li>
                <li>
                <img src='./images/circle_list.png'/>
                <p>Click on My Account or Login/Sign-up with Email I'd and Password or Login with Mobile OTP.</p>
                </li>
                <li>
                <img src='./images/circle_list.png'/>
                <p>Add item to your Shopping Cart.</p>
                </li>
                <li>
                <img src='./images/circle_list.png'/>
                <p>Choose convenient delivery time from our slots a day</p>
                </li>
                <li>
                <img src='./images/circle_list.png'/>
                <p>Select suitable multi-mode payment options. Cash/Paid On Delivery (COD/POD) and/or Pay using your Credit card / Debit Card / Internet Banking through banking gateway.</p>
                </li>
                <li>
                <img src='./images/circle_list.png'/>
                <p>Your Grocery order will be delivered at your doorstep on time on the same day. You will get a message as well as an e-mail notification for Order Received, Order Out for Delivery, and Order has been Completed. Thank You for Shopping with DHANSHEERA.</p>
                </li>
                <li>
                <img src='./images/circle_list.png'/>
                <p>In the case of late due to any reason, we will notify you until unless not delivered at your door step.</p>
                </li>
                <li>
                <img src='./images/circle_list.png'/>
                <p>
In case of receiving any missing products, damaged products, expired products, kind requests immediately contact DHANSHEERA Customer support with evidence like clear visibility photos (minimum 5 numbers each) through Phone call Support, Email Support, and/or WhatsApp Support.</p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col>
            <h5>DHANSHEERA - ORDER PACKAGING POLICY:</h5>
            <p>Packing your orders carefully and neatly will ensure that buyers receive them in good condition. By using the right packing techniques and good packaging materials. We have charged Rs.5 on every multiple order value of Rs.1000.</p>
            <p>This is non-refundable once the product is packed and the buyer requested cancellation.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{margin:"50px 0"}}>
      <Container>
        <Row>
          <Col>
            <h5>DHANSHEERA - SHIPPING AND DELIVERY POLICY:</h5>
            <p>We are committed to delivering orders on time, which are of better quality and adequately packaged. Upon receiving an order, it will be informed to the dispatch center (DC) to process the order and appropriately packed for delivery. Our delivery agent has been notified to pick up the order from the dispatch center to deliver the order to the shipping address. We further request our customers to kindly guide the delivery agent in locating and reaching the delivery location over the phone, if the delivery agent cannot trace the location. Once the order has been shipped for delivery, an intimation will be made to the customer through mail or message. All orders will be delivered on the same day as per the chosen time slot, if there is any problem with delivery, it will be intimated to the customer over the phone. Free Shipping is allowed only for orders with Rs. 599/- and above cart value (Variable as per Area Pin Codes), except that all orders are chargeable.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{margin:"50px 0"}}>
      <Container>
        <Row>
          <Col>
            <h5>DHANSHEERA - AFTER DISPATCH CANCELLATION POLICY:</h5>
            <p>You can cancel the order before it is dispatched for delivery. After dispatching the order if you request to cancel the order then the cancellation charge of Rs59/- (The Delivery Charges are variable as per Area Pin Codes) will be levied.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{margin:"50px 0"}}>
      <Container>
        <Row>
          <Col>
            <h5>POPULAR CATEGORIES:</h5>
            <p>Grocery & Organic Staples - Your Daily Store | Household Essentials | Juice & Beverages | Branded Foods | Bakery & Dairy | Baby Products and Personal Care |</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{margin:"50px 0"}}>
      <Container>
        <Row>
          <Col>
            <h5>CITIES WE SERVE:</h5>
            <p>India (PAN India through Third Party Delivery Agencies) | Uttar pardesh (Bhubaneswar | Cuttack | Niali, Uttar pardesh | Pipili, Puri | Jatni, Khurda | Salepur, Cuttack | Rourkela | Angul, Talcher | Sambalpur | Baleswar | Bhadrak | Berhampur | Paradeep, Jagatsingpur) and Other cities soon...)</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col>
            <h5>PAYMENT OPTIONS:</h5>
            <p><img style={{width:"60%"}} src='./images/payment_options.png'/></p>
          </Col>
        </Row>
      </Container>
    </section>

    

    </>
   
  )
}

export default MainSection;
